var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"operation-license"},[_c('h2',[_vm._v("Licença de operação:")]),_c('label',[_c('span',[_vm._v("Licença enviada: ")]),_c('a',{attrs:{"target":"_blank","href":_vm.$help.image(_vm.cityMaterial.destionation_certification)}},[_vm._v(" visualizar arquivo ")])]),(_vm.accepedCertification == 'Expirado')?_c('SendCertification',{attrs:{"material-id":_vm.cityMaterial.city_company_item_id}}):_vm._e(),_c('label',[_c('span',[_vm._v("Status: ")]),_c('span',{class:{
        'await-approve': _vm.accepedCertification != 'Aprovado',
        approved: _vm.accepedCertification == 'Aprovado'
      }},[_vm._v(" "+_vm._s(_vm.accepedCertification)+" ")])]),_c('label',[_c('span',[_vm._v("Data de expiração: ")]),_c('span',{class:{
        'await-approve': _vm.accepedCertification != 'Aprovado',
        approved: _vm.accepedCertification == 'Aprovado'
      }},[_vm._v(" "+_vm._s(_vm.expiration)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }