<template>
  <div class="operation-license">
    <h2>Licença de operação:</h2>
    <label>
      <span>Licença enviada: </span>
      <a
        target="_blank"
        :href="$help.image(cityMaterial.destionation_certification)"
      >
        visualizar arquivo
      </a>
    </label>

    <SendCertification
      :material-id="cityMaterial.city_company_item_id"
      v-if="accepedCertification == 'Expirado'"
    />

    <label>
      <span>Status: </span>
      <span
        :class="{
          'await-approve': accepedCertification != 'Aprovado',
          approved: accepedCertification == 'Aprovado'
        }"
      >
        {{ accepedCertification }}
      </span>
    </label>
    <label>
      <span>Data de expiração: </span>
      <span
        :class="{
          'await-approve': accepedCertification != 'Aprovado',
          approved: accepedCertification == 'Aprovado'
        }"
      >
        {{ expiration }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    material: { type: Object, required: true },
    itemId: { type: [Number, String], required: true }
  },
  components: {
    SendCertification: () => import('./send-certification.vue')
  },
  computed: {
    cityMaterial () {
      let material = {}
      const idItem = this.itemId
      const filter = [...(this.material.company_items || [])].filter(
        item => parseInt(item.city_company_item_id) === parseInt(idItem)
      )

      if (filter.length) material = filter[0]
      return material
    },
    expiration () {
      if (
        !this.cityMaterial.destionation_certification &&
        !this.cityMaterial.destionation_certification_accepted
      ) {
        return 'Não fornece certificado'
      }
      const expiration = this.cityMaterial.destionation_certification_expiration
      if (!expiration) return 'Aguardando'
      return expiration
        .split('-')
        .reverse()
        .join('/')
    },
    accepedCertification () {
      if (
        !this.cityMaterial.destionation_certification &&
        !this.cityMaterial.destionation_certification_accepted
      ) {
        return 'Não fornece certificado'
      }
      let status =
        this.material.company_items.length &&
        this.cityMaterial.destionation_certification_accepted
          ? 'Aprovado'
          : 'Aguardando'

      const expiration = this.material.company_items.length
        ? this.cityMaterial.destionation_certification_expiration
        : null

      if (expiration) {
        const today = new Date().getTime()
        if (new Date(expiration).getTime() < today) status = 'Expirado'
      }

      return status
    }
  }
}
</script>

<style lang="sass" scoped>
.operation-license
  color: #000

  label, h2
    font-weight: bold
    display: flex
    margin-bottom: 0.75rem

  h2
    font-size: 20px
    margin-bottom: 1.25rem

  label
    justify-content: flex-start
    align-items: center

    a, .await-approve
      font-weight: normal
      margin-left: 0.1rem

    a
      text-decoration: underline
      color: #16b666

    span, a
      margin-right: 0.5rem
</style>
